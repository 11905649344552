import React from "react"
import image1 from "../images/school/school4.jpg"
import image2 from "../images/bakery/bakery5.jpg"
import image3 from "../images/2691.jpg"
import facebook from "../images/icons8-facebook-48.png"
import instagram from "../images/icons8-instagram-48.png"
import store from "../images/icons8-online-store-48.png"
import vid8 from "../videos/furn.gif"
import "./what-we-do.css"
import "animate.css"

export default function WhatWeDo() {
  return (
    <div id="home" className="home">
      <div style={{ display: "contents" }}>
        <section className="intro-title">
          <div className="title">Check out what we are doing now.</div>
        </section>
        <section className="what-text-2">
          <div className="image-container">
            <div className="image">
              <div className="image-wrapper">
                <div className="img-view">
                  <picture></picture>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="text-container">
              <div className="header-container">
                <div className="header-text">
                  <span>2024</span>
                </div>
                <div className="header-text">
                  <span>Updates</span>
                </div>
              </div>
              <div
                className="body-container-update"
                style={{ columnCount: "0" }}
              >
                <div className="body-text">
                  <p>
                    As we conclude 2024, we extend our heartfelt gratitude to
                    you, our generous donors, whose unwavering support has
                    empowered us to achieve so much this year. Every dollar
                    donated and every article of clothing contributed has made a
                    tangible difference, enabling us to continue our mission of
                    aiding struggling communities in the mountains of Lebanon.
                  </p>
                  <p>
                    This year, we are proud to share the impact of your
                    generosity in the Matn region, which includes towns like
                    Sawfar and Sharoun, as well as their public schools, whose
                    student populations come from surrounding areas:
                  </p>
                  <ol>
                    <li>
                      <strong>Raised $3,500</strong> in private donations for
                      Sawfar’s Public School, supporting a range of needs from
                      student services and building maintenance to heating costs
                      and assistance for low-income students.
                    </li>
                    <li>
                      <strong>Raised $2,400</strong> in private donations for
                      Sharoun’s Public School, addressing similar critical
                      expenses.
                    </li>
                    <li>
                      <strong>
                        Collected and shipped over 400 pounds of winter clothing
                      </strong>
                      , which were distributed to families in need.
                    </li>
                    <li>
                      <strong>
                        Secured over 100 boxes of over-the-counter medications,
                        first aid supplies, and vitamins
                      </strong>
                      , valued at over $350, which were distributed to families
                      in need.
                    </li>
                    <li>
                      <strong>Provided $1,000</strong> to help in funding a
                      life-saving surgery for a 4-year-old boy.
                    </li>
                    <li>
                      <strong>Raised $1,000</strong> to support a young mother
                      and her children following the sudden loss of her husband.
                    </li>
                    <li>
                      <strong>Secured over $500 in diabetes supplies</strong>{" "}
                      for a family with children requiring essential care.
                    </li>
                  </ol>
                  <p>
                    As 2024 comes to a close, there is still time to make your
                    tax-deductible donation to support these vital efforts,
                    especially as these communities face the cold winter months
                    ahead. Lebanon has endured significant hardships this year,
                    but with your continued support, we remain hopeful for
                    brighter days ahead.
                  </p>
                </div>
              </div>
            </div>
            <div className="text-container">
              <div className="header-container">
                <div className="header-text">
                  <span>2023</span>
                </div>
                <div className="header-text">
                  <span>Updates</span>
                </div>
              </div>
              <div
                className="body-container-update"
                style={{ columnCount: "0" }}
              >
                <div className="body-text">
                  <div style={{ display: "contents" }}>
                    <p style={{ marginBottom: "1.35em" }}>
                      <p>
                        So much has happened in 2023, but our focus and
                        determination have remained steadfast. Our dedication to
                        aiding struggling communities in the mountains of
                        Lebanon has endured, and we've achieved significant
                        milestones thanks to your generosity. Amid the turmoil
                        of these challenging times, we've accomplished the
                        following in the Matn region, which includes towns like
                        Sawfar and Sharoun, as well as Sawfar’s public school,
                        whose student body hails from many surrounding towns:
                      </p>
                      <ol>
                        <li>
                          Continued funding for a free lunch program for
                          students in Sawfar’s public school, spanning from
                          kindergarten to 12th grade.
                        </li>
                        <li>
                          Donated the remaining winter clothing collected
                          through last year’s winter clothing drive to children
                          and families in need.
                        </li>
                        <li>
                          Distributed over 100 boxes of powdered Enfamil baby
                          formula to several low-income families.
                        </li>
                        <li>
                          Raised $1,000 in two fundraising events, directing the
                          funds to Sawfar public school for purchasing kerosene
                          supply for the year to be used for heating classrooms.
                        </li>
                        <li>Secured $5,000 in private donations.</li>
                        <li>
                          Distributed over 300 boxes of over-the-counter pain
                          relief medication, first aid supplies, and vitamins.
                        </li>
                        <li>
                          Provided several Diabetes and glucose monitoring
                          testing kits with testing strips to families with
                          diabetic children.
                        </li>
                        <li>
                          Covered the cost of an emergency life-saving surgery
                          for a teacher.
                        </li>
                        <li>
                          Assisted a student and her mother in leaving a
                          domestic violence situation.
                        </li>
                        <li>
                          Granted an end-of-year $50 Christmas bonus each, to 21
                          hardworking teachers.
                        </li>
                      </ol>
                      <p>
                        We look forward to continuing our direct impact and
                        making a significant difference in the small mountain
                        towns of Lebanon. Your tax-deductible donation for this
                        year can still be made! Remember that due to the
                        difference in currency rates between the Dollar and the
                        Lebanese Lira, a small amount can make a substantial
                        impact.
                      </p>
                      {/* <p>To learn more, follow us on our social media:</p> */}
                      {/* <ul style={{ listStyleType: "none" }}>
                        <li>
                          <a
                            className="instagram-link"
                            target="_blank"
                            href="https://www.facebook.com/HaydaBeytna"
                          >
                            <img
                              src={facebook}
                              alt="Facebook Icon"
                              width="20"
                              height="20"
                            />
                            Beytna Charitable Foundation
                          </a>
                        </li>
                        <li>
                          <a
                            className="instagram-link"
                            target="_blank"
                            href="https://instagram.com/BeytnaFoundation"
                          >
                            <img
                              src={instagram}
                              alt="Instagram Icon"
                              width="20"
                              height="20"
                            />
                            BeytnaFoundation
                          </a>
                        </li>
                        <li>
                          <a
                            className="instagram-link"
                            target="_blank"
                            href="https://www.beytna-store.com/"
                          >
                            <img
                              src={store}
                              alt="Store Icon"
                              width="20"
                              height="20"
                            />
                            Beytna-Store.com
                          </a>
                        </li>
                      </ul> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="what-text-1">
          <div className="image-text">
            <div className="text-left-top">
              <div className="headline">Furn Charoun</div>
              <div className="headline">El Kheiri</div>
            </div>
            <div className="text-left-bot">
              <div className="body">
                <div style={{ display: "contents" }}>
                  <p style={{ marginBottom: "1.35em" }}>
                    Our foundation is currently funding a local bakery called
                    Turn Charoun El Kheiri in the town of Charoun, Lebanon.
                    Volunteers bake pita bread and then package it so that local
                    families can get the bread free of charge. The bakery also
                    delivers the bread to the small town and communities that
                    have no means of transportation to reach the bakery.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="image">
            <div className="image-wrap">
              <div className="image-left-mask">
                <img className="video" src={vid8} alt="Your GIF" />
                {/* <video
                  className="video"
                  autoplay="autoplay"
                  preload
                  playsinline
                  muted="muted"
                  loop="loop"
                  poster={image2}
                >
                  <source src={vid8} type="video/mp4" />
                  <track
                    src={vid8}
                    kind="captions"
                    srclang="en"
                    label="english_captions"
                  />
                </video> */}
              </div>
            </div>
            <div className="caption">
              <div className="title">Charoun, Lebanon</div>
            </div>
          </div>
        </section>
        <section className="what-text-2">
          <div className="image-container">
            <div className="image">
              <div className="image-wrapper">
                <div className="img-view">
                  <picture>
                    <img src={image1} className="img-1" alt="school" />
                  </picture>
                </div>
              </div>
            </div>
          </div>
          <div className="text-container">
            <div className="header-container">
              <div className="header-text">
                <span>Public</span>
              </div>
              <div className="header-text">
                <span>School</span>
              </div>
              <div className="header-text">
                <span>of Sawfar</span>
              </div>
            </div>
            <div className="body-container">
              <div className="body-text">
                <div style={{ display: "contents" }}>
                  <p style={{ marginBottom: "1.35em" }}>
                    We are also working with the public school of Sawfar. Funds
                    are currently being used to restock kerosene for classroom
                    heaters, as well as school and food supplies to allow
                    teachers to make sandwiches to distribute to kids who cannot
                    afford lunch. We hosted a very successful fundraiser in
                    November of 2022 to purchase solar panels that would provide
                    the school (and hopefully other schools in the future) with
                    electricity during the day. These panels have successfully
                    been installed, thanks to the kindness and generosity of
                    donors. Without these panels, students only receive an hour
                    of electricity per day from public facilities. The need for
                    these services are urgent to provide not only heating during
                    the Winter, but also to power equipment that facilitates
                    learning.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="what-text-1">
          <div className="image-text">
            <div className="text-left-top">
              <div
                className="headline"
                style={{ fontSize: "calc(6.37647px + 3.11765vw)" }}
              >
                Annual Winter
              </div>
              <div
                className="headline"
                style={{ fontSize: "calc(6.37647px + 3.11765vw)" }}
              >
                Clothing Drive
              </div>
            </div>
            <div className="text-left-bot">
              <div className="body">
                <div style={{ display: "contents" }}>
                  <p style={{ marginBottom: "1.35em" }}>
                    During the Summer of 2022, we were fortunate enough to
                    collect many winter clothes to give away to those in need as
                    they prepared for the harsh Winter ahead. Lebanese mountain
                    communities experience heavy snowfall that lasts for 4-6
                    months of the year. Thank you so much to everyone who
                    participated! We will continue to host this event annually
                    during the month of June so that we can send the items to
                    Lebanon before the Fall.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="image">
            <div className="image-wrap">
              <div className="image-left-mask">
                <picture>
                  <img src={image3} className="img" alt="drive" />
                </picture>
              </div>
            </div>
            {/* <div className="caption">
              <div className="title">Charoun, Lebanon</div>
            </div> */}
          </div>
        </section>
        <footer className="footer">
          <div className="main">
            <div className="interest">
              <div className="headline">
                <div className="parent">
                  <div className="text animate__animated animate__slideInUp">
                    Ready to help?
                  </div>
                </div>
              </div>
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=UHGSRNCC822XC"
                target="_blank"
                rel="noreferrer"
                className="content-head"
              >
                <div className="content">
                  <div className="label">
                    <div className="index-rolling-text">Donate</div>
                  </div>
                  <div class="wrapper">
                    <a
                      href="https://www.paypal.com/donate/?hosted_button_id=UHGSRNCC822XC"
                      target="_blank"
                      rel="noreferrer"
                      class="chevron-d"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        viewBox="0 0 55 55"
                      >
                        <path
                          class="circle"
                          d="M27.5 2C41.6 2 53 13.4 53 27.5S41.6 53 27.5 53 2 41.6 2 27.5 13.4 2 27.5 2M27.5 0C12.3 0 0 12.3 0 27.5S12.3 55 27.5 55 55 42.7 55 27.5 42.7 0 27.5 0L27.5 0z"
                        />
                        <polygon
                          class="arrow"
                          points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                        />
                        <polygon
                          class="arrow-2"
                          points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </a>
            </div>
            <div className="top">
              <div className="logo-box">
                {/* <div className="logo">Site Under Construction</div> */}
              </div>
              <div className="info-container">
                <div className="list-container">
                  <ul style={{ padding: "0", listStyle: "none" }}>
                    {/* <li className="address">
                        Address Here
                        <br />
                        Here
                        <br />
                        Here
                      </li> */}
                    {/* <li className="phone">+34 93 350 55 08</li> */}
                    <li className="email-container">
                      <a className="email" href="mailto:info@beytna.foundation">
                        info@beytna.foundation
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="list-container-2">
                  <ul style={{ padding: "0", listStyle: "none" }}>
                    {/* <li className="phone">phone number</li> */}
                    <li className="email-container">
                      <a className="email" href="mailto:info@beytna.foundation">
                        info@beytna.foundation
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="list-container-3">
                  <ul style={{ padding: "0", listStyle: "none" }}>
                    <li className="legal">
                      <a className="item" href="/#">
                        Legal disclaimer
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bottom">
              <div className="item">
                ©2022 Beytna Charitable Foundation. All rights reserved.
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
